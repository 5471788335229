.project__details__modal {
  width: 80vw;
  height: 100vh;
  background: var(--bg-color);
  border-radius: 20px;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  transition: all 1.5s ease;
  color: var(--txt-Black);
}

.overlay__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
}

.project__details__modal h1 {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.project__details__modal h4 {
  color: var(--txt-Black);
  font-size: 16px;
  font-weight: 500;
}

.project__details__modal button.submit {
  padding: 15px 30px;
  background-color: var(--primary-color);
  color: var(--txt-White);
  border-radius: 5px;
  cursor: pointer;
}

.project__details__modal .project__details__modal__flex {
  display: flex;
}

.project__details__modal
  .project__details__modal__flex
  .project__details__modal__flex__50percent {
  width: calc(50% - 20px);
  margin: 0 10px;
}

.multi__select {
  margin: 10px 0;
}

.project__details__modal .project__details__modal__flex__100percent__last {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  color: var(--primary-color);
  cursor: pointer;
}

.project__details__modal .project__details__modal__flex__100percent__last img {
  margin-right: 5px;
}

.tags {
  background-color: var(--bg-color2);
  width: 100%;
  border-radius: 5px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.tags .tag {
  background-color: var(--grey);
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
