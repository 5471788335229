.admin__modal {
  width: 450px;
  height: 450px;
  background: var(--bg-color);
  border-radius: 20px;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  transition: all 1.5s ease;
  color: var(--txt-Black);
}

.overlay__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
}

.admin__modal h1 {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.admin__modal h4 {
  color: var(--txt-Black);
  font-size: 16px;
  font-weight: 500;
}

.admin__modal .select__area {
  margin-top: 50px;
}

.admin__modal .select__area .select {
  border: none;
  outline: none;
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: var(--bg-color2);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50' style='fill:%23000000;' /></svg>");
  background-position: right 20px top 50%;
  background-repeat: no-repeat;
  background-size: 14px;
}

.admin__modal .select__area .select option {
  background-color: var(--bg-color);
}

.admin__modal button.submit {
  padding: 15px 30px;
  background-color: var(--primary-color);
  color: var(--txt-White);
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.admin__modal button.submit.disabled {
  background-color: var(--grey);
}

.btn__group {
  display: flex;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 60px);
  align-items: center;
  justify-content: space-between;
}

.check__box__area {
  margin-top: 100px;
}

.check__box__area .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.check__box__area .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.check__box__area .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #b6c5d1;
  box-sizing: border-box;
  border-radius: 3px;
}
.check__box__area .styled-checkbox:hover + label:before {
  background: var(--primary-color);
}
.check__box__area .styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.check__box__area .styled-checkbox:checked + label:before {
  background: var(--primary-color);
}
.check__box__area .styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.check__box__area .styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.check__box__area .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
