.constructor__profile {
  display: flex;
  align-items: center;
  line-height: 40px;
  margin-bottom: 50px;
}

.constructor__details button.danger {
  padding: 15px 40px;
  background-color: rgba(207, 98, 98, 0.1);
  border-radius: 5px;
  color: #cf6262;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
}

.constructor__details button.empty {
  padding: 15px 40px;
  background-color: transparent;
  border-radius: 5px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.constructor__profile img,
.constructor__profile svg {
  height: 100px;
  width: 100px;
  margin-right: 20px;
}
