.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 35px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 3px;
  content: "";
  height: 15px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 15px;
}

.switch input:checked + .slider {
  background-color: #66bb6a;
}

.switch input:checked + .slider:before {
  transform: translateX(15px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
