@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
.topbar {
  font-family: "Quicksand", sans-serif;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-color);
  padding-left: 120px;
  padding-right: 50px;
  position: fixed;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.topbar h1 {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.topbar .profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar .profile p {
  font-size: 14px;
  margin: 0 10px;
  font-weight: bold;
}

.topbar .menu {
  background-color: var(--bg-color);
  position: absolute;
  top: 80px;
  right: 40px;
  padding: 10px 50px;
  border-radius: 10px;
  border: 1px solid var(--bg-color2);
  transition: all 0.5s ease;
}

.topbar .menu p {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
  cursor: pointer;
}
