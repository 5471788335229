.profile__modal {
  width: 450px;
  height: 350px;
  background: var(--bg-color);
  border-radius: 20px;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  transition: all 1.5s ease;
  color: var(--txt-Black);
}

.overlay__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
}

.profile__modal h1 {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.profile__modal h4 {
  color: var(--txt-Black);
  font-size: 16px;
  font-weight: 500;
}

.profile__modal .select__area {
  margin-top: 50px;
}

.profile__modal .select__area .select {
  border: none;
  outline: none;
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: var(--bg-color2);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50' style='fill:%23000000;' /></svg>");
  background-position: right 20px top 50%;
  background-repeat: no-repeat;
  background-size: 14px;
}

.profile__modal .select__area .select option {
  background-color: var(--bg-color);
}

.profile__modal button.submit {
  padding: 15px 30px;
  background-color: var(--primary-color);
  color: var(--txt-White);
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
