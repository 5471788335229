.bottom__stats {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom__stats .left__side,
.bottom__stats .right__side {
  width: 50%;
}

.bottom__stats .right__side {
  padding: 20px;
}

.bottom__stats .right__side .inner__box {
  margin: 20px;
  background: var(--bg-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 0.928364px solid #f0f3f6;
  box-shadow: 0px 4px 200px rgba(0, 0, 0, 0.04);
  padding: 30px 0;
}

.bottom__stats .right__side .inner__box .icon {
  padding: 20px;
  opacity: 0.65;
  border-radius: 100%;
  margin: 0 20px;
}

.bottom__stats .right__side .inner__box .icon__4 {
  background: #e8d7f8;
}

.bottom__stats .right__side .inner__box .icon__5 {
  background: #fffbec;
}

.bottom__stats .right__side .inner__box .data h2 {
  color: var(--txt-Black2);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.bottom__stats .right__side .inner__box .data h1 {
  color: var(--txt-Black);
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
}

@media screen and (max-width: 1024px) {
  .bottom__stats {
    flex-direction: column;
  }
  .bottom__stats .left__side,
  .bottom__stats .right__side {
    width: 100%;
    
  }
}
