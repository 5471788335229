.filter__box {
  width: 100%;
  padding: 25px 10px;
  background: var(--bg-color);
  border: 2px solid var(--bg-color2);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.filter__box div {
  margin: 0 5px;
}

.filter__box div h4 {
  margin: 5px;
  font-weight: 600;
}

.filter__box .search__btn button {
  padding: 10px;
  width: 100%;
  margin-top: 30px;
  color: var(--txt-Black);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.filter__box .search__btn button.disabled {
  background: rgba(182, 197, 209, 0.4);
  border-radius: 5px;
}

.filter__box .search__btn button.primary {
  background: var(--primary-color);
  border-radius: 5px;
}

.filter__box .project__filter,
.filter__box .client__filter {
  width: calc(25% - 10px);
}

.filter__box .achievement__filter,
.filter__box .date__filter {
  width: calc(18% - 10px);
}

.filter__box .search__btn {
  width: calc(14% - 10px);
}
