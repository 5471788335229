textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border: 2px solid var(--bg-color2);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px 0;
}

textarea.grey {
  background-color: var(--bg-color2);
}
