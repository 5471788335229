select.select__field__area {
  border: none;
  outline: none;
  width: 100%;
  padding: 11px 20px;
  border-radius: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50' style='fill:%23b6c5d1;' /></svg>");
  background-position: right 20px top 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  margin: 10px 0;
}

select.select__field__area.white {
  background-color: var(--bg-color);
  border: 1px solid var(--grey);
  color: var(--grey);
}

select.select__field__area.grey {
  background-color: var(--bg-color2);
  color: var(--txt-Black);
}

select.select__field__area option {
  background-color: var(--bg-color);
}
